<template>
  <div class="relative mx-auto max-w-[var(--breakpoint-xl)] px-4 sm:px-8">
    <section class="mt-32 w-full">
      <div>
        <div class="text-base font-bold text-yellow-400 uppercase">
          {{ blok.subheading || 'Overview' }}
        </div>
        <h2 class="mt-0.5 text-3xl font-extrabold text-white">
          {{ blok.headline }}
        </h2>
      </div>
      <div class="mt-6 grid grid-cols-2 gap-6 md:grid-cols-4">
        <div v-for="(activity, index) in sortedActivities" :key="index">
          <LazyMarketingCardActivity :activity="activity" :blok="blok" />
        </div>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  blok: {
    type: Object,
    default: () => ({}),
  },
  uuid: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: '',
  },
});

const sortedActivities = computed(() => {
  return [...props.blok.activities].sort((a, b) => {
    if (b.content.products[0].price !== a.content.products[0].price) {
      return b.content.products[0].price - a.content.products[0].price;
    }
    return a.name.localeCompare(b.name);
  });
});
</script>
